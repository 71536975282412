const randomHex = require('random-hex-color')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')

const randDeg = ({ type }) => {
  if (type === 'repeating-linear') {
    return `${Math.floor(Math.random() * Math.floor(180))}`
  } else {
    return `${Math.floor(Math.random() * Math.floor(360))}`
  }
}

const randDist = () => [
  Math.floor(Math.random() * Math.floor(40)),
  70 + Math.floor(Math.random() * Math.floor(30))
]

const toGradient = ({
  startColor,
  startColorHinting,
  endColor,
  endColorHinting,
  degrees = randDeg(),
  type = 'linear',
  size = 10,
  ratio = 3
}) => {
  if (type === 'linear') {
    return `linear-gradient(${degrees}deg, ${startColor} ${startColorHinting}%, ${endColor} ${endColorHinting}%)`
  }
  if (type === 'repeating-linear') {
    return `repeating-linear-gradient(${degrees}deg, ${startColor}, ${startColor} ${size}px, ${endColor} ${size}px, ${endColor} ${size *
      ratio}px)`
  }
  if (type === 'radial') {
    return `${type}-gradient(${startColor} ${startColorHinting}%, ${endColor} ${endColorHinting}%)`
  }
}

const generateGradient = (startColor, { type = 'linear' } = {}) => {
  startColor = startColor || randomHex()

  const { end: endColor, fontShouldBeLight } = adaptiveGradient(startColor)
  const [startColorHinting, endColorHinting] = randDist()

  const gradient = {
    color: fontShouldBeLight ? 'white' : 'black',
    startColor,
    startColorHinting,
    endColor: '#' + endColor,
    endColorHinting,
    degrees: randDeg({ type }),
    backgroundColor: startColor
  }

  return {
    ...gradient,
    backgroundImage: toGradient(gradient)
  }
}

const generateRepeatingGradient = (
  size = randomInt(1, 10),
  ratio = randomInt(1, 10)
) => {
  const gradient = generateGradient(null, { type: 'repeating-linear' })

  return {
    ...gradient,
    backgroundImage: toGradient({ ...gradient, type: 'repeating-linear' }),
    size,
    ratio
  }
}

generateGradient.toBackgroundImage = toGradient
generateGradient.repeating = generateRepeatingGradient
module.exports = generateGradient
