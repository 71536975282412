import React, { useState, useEffect } from 'react'
import { Grid, ArrowRight, Heart } from 'react-feather'

import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomGradient from '../lib/generate/gradient'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ gradient }) => (
  <div
    css={{
      minHeight: '16rem',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      ...gradient
    }}
  />
)

const GridItem = ({ gradient, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '19vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'block',
        height: '100%',
        width: '100%',
        ...gradient
      }}
      {...props}
    />
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(20)
      .fill(0)
      .map((_, i) => {
        const baseGradient = randomGradient.repeating()
        const gradientWithPins = { ...baseGradient, ...pins }
        const gradient = {
          ...gradientWithPins,
          backgroundImage: randomGradient.toBackgroundImage({
            ...gradientWithPins,
            type: 'repeating-linear'
          })
        }

        return (
          <GridItem
            key={i}
            onClick={() => onSelect(gradient)}
            gradient={gradient}
          />
        )
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Gradient</SidebarHeading>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.degrees}
          onClick={() => onPin('degrees', value.degrees)}
        />
        <Label>Degrees</Label>
        <Input
          min={0}
          max={180}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.degrees)}
          type="range"
          onChange={changeValue('degrees')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.degrees}
        />
      </Flex>
      <Color
        value={value.startColor}
        onChange={changeValue('startColor')}
        active={pins.startColor}
        onLock={() => onPin('startColor', value.startColor)}
        width={128}
      />
      <Color
        value={value.endColor}
        onChange={changeValue('endColor')}
        active={pins.endColor}
        onLock={() => onPin('endColor', value.endColor)}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.ratio}
          onClick={() => onPin('ratio', value.ratio)}
        />
        <Label>Ratio</Label>
        <Input
          type="range"
          min={1}
          max={10}
          step={0.1}
          width={1}
          mr={2}
          value={value.ratio}
          onChange={changeValue('ratio')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.ratio}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.size}
          onClick={() => onPin('size', value.size)}
        />
        <Label>Size</Label>
        <Input
          type="range"
          width={1}
          mr={2}
          min={1}
          max={40}
          value={value.size}
          onChange={changeValue('size')}
        />
        <Span
          width="80px"
          textAlign="right"
          fontSize={0}
          children={value.size + 'px'}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={randomGradient.toBackgroundImage({
          ...value,
          type: 'repeating-linear'
        })}
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const Gradient = styled('div')({\n" +
          `  backgroundImage: '${randomGradient.toBackgroundImage({
            ...value,
            type: 'repeating-linear'
          })}'` +
          '\n})\n'
        }
      />
    </>
  )
}

export default () => {
  const [gradient, setGradient] = useState(randomGradient.repeating())
  const [pins, setPins] = useState({})
  const [gridView, setGridView] = useState(false)

  const gradientWithPins = { ...gradient, ...pins }
  const fullGradient = {
    ...gradientWithPins,
    backgroundColor: gradientWithPins.startColor,
    backgroundImage: randomGradient.toBackgroundImage({
      ...gradientWithPins,
      type: 'repeating-linear'
    })
  }

  const upvote = () => {
    if (gridView) {
      return
    }

    db.collection('components')
      .doc('stripes')
      .collection('votes')
      .add({
        up: true,
        data: fullGradient,
        metadata: { pins }
      })

    setGradient(randomGradient.repeating())
  }
  const skip = () => {
    setGradient(randomGradient.repeating())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = gradient => {
    setGradient(gradient)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main gradient={fullGradient} />
        )
      }
      sidebar={
        <Sidebar
          value={fullGradient}
          pins={pins}
          onChange={(key, value) => {
            setGradient({
              ...gradient,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <Flex>
            <VoteButton
              disabled={gridView}
              css={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
